import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const HeadingRow = ({ children }) => (
  <div className="fpl__header-row">{children}</div>
);

HeadingRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeadingRow;
