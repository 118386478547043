import React from "react";
import uuidV4 from "uuid/v4";
import PropTypes from "prop-types";
import "./style.css";

const createTabStateFromChildren = (children) =>
  React.Children.map(
    children,
    ({
      props: {
        label, labelIcon, selected, children: tabChildren, ...rest
      },
    }) => ({
      label,
      labelIcon,
      selected,
      id: uuidV4(),
      children: tabChildren,
      rest,
    }),
  );

const Tabs = ({ children, ...rest }) => {
  const [{ tabs }, setState] = React.useState({
    tabs: createTabStateFromChildren(children),
  });

  const makeHandleTabChange = (id) => () => {
    setState((state) => ({
      tabs: state.tabs.map((tab) => ({
        ...tab,
        selected: tab.id === id,
      })),
    }));
  };

  return (
    <div className="fpl__tabs--container" {...rest}>
      <div
        className="fpl__tabs--buttons-container"
        role="tablist"
        aria-label="fpl__tabs"
      >
        {tabs.map(
          ({
            selected, label, id, rest: tabRest, children: tabChildren,
          }) => (
            <div key={id}>
              <button
                className="fpl__tabs--button"
                role="tab"
                aria-selected={!!selected}
                aria-controls={`${id}-tab`}
                id={id}
                onClick={makeHandleTabChange(id)}
                type="button"
                {...tabRest}
              >
                <h3 className="fpl__tabs--label__text">{label}</h3>
              </button>
              <div
                key={`${id}-tab`}
                role="tabpanel"
                id={`${id}-tab`}
                aria-labelledby={id}
                hidden={!selected}
                className="fpl__tabs--tabpanel"
              >
                {tabChildren}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
};

Tabs.defaultProps = {
  children: [],
};

export default Tabs;
