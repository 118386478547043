import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import uuidV4 from "uuid/v4";
import Layout from "../components/layout";
import SafeZone from "../components/safeZone";
import HeadingRow from "../components/headingRow";
import SEO from "../components/seo";
import Tab from "../components/tab";
import Tabs from "../components/tabs";

const FAQ = ({
  data: {
    allPrismicFaq: {
      edges: [
        {
          node: {
            data: {
              question: questions,
              title: {
                raw: [{ text: title }],
              },
            },
          },
        },
      ],
    },
  },
}) => (
  <>
    <SEO title={title} />
    <Layout>
      <SafeZone>
        <HeadingRow>
          <h1>{title}</h1>
        </HeadingRow>
        <Tabs>
          {questions.map(
            (
              {
                answer: { raw },
                question1: {
                  raw: [{ text }],
                },
              },
              index,
            ) => (
              <Tab key={uuidV4()} label={text} selected={index === 0}>
                {RichText.render(raw)}
              </Tab>
            ),
          )}
        </Tabs>
      </SafeZone>
    </Layout>
  </>
);

FAQ.propTypes = {
  data: PropTypes.object,
};

FAQ.defaultProps = {
  data: null,
};

export default FAQ;

export const query = graphql`
  query {
    allPrismicFaq {
      edges {
        node {
          id
          data {
            question {
              answer {
                raw {
                  spans {
                    end
                    start
                    type
                  }
                  text
                  type
                }
              }
              question1 {
                raw {
                  text
                  type
                }
              }
            }
            title {
              raw {
                text
                type
              }
            }
          }
        }
      }
    }
  }
`;
