import React from "react";
import PropTypes from "prop-types";

const Tab = ({ children }) => <>{children}</>;

Tab.defaultProps = {
  selected: false,
};

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  label: PropTypes.node.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selected: PropTypes.bool,
};

export default Tab;
